function __MN_KL(klaviyo_list_id, klaviyo_api_key, shopify_token, cors_url, data_json) {
    return new Promise(function (resolve, reject) {
        var xmlhttp = new XMLHttpRequest(); // new HttpRequest instance
        var theUrl = cors_url + 'https://a.klaviyo.com/api/v2/list/' + klaviyo_list_id + '/members?api_key=' + klaviyo_api_key;
        xmlhttp.open("POST", theUrl);
        xmlhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xmlhttp.setRequestHeader('X-Requested-With', '*');
        xmlhttp.setRequestHeader('X-Shopify-Access-Token', shopify_token);
        xmlhttp.send(JSON.stringify({
            "profiles": [ 
                data_json
            ]
        }));
        xmlhttp.onload = function () {
            var return_data = {
                "status": xmlhttp.status,
                "data": JSON.parse(xmlhttp.response),
            }
            return resolve(return_data);
        };
    });
}


